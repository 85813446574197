import React from "react";
import "../Text/Text.css";

const Text = () => {
  return (
    <div className="luxury-homes-section">
      <div className="container2">
        <h2 className="section-heading">Ace Hanei Noida Extension</h2>
        
        <p className="section-text">
          Welcome to <strong>ACE Hanei</strong> Sector 12 Noida Extension, a prestigious residential project crafted by the esteemed ACE Group. This development offers a selection of lavish 3.5 BHK and 4.5 BHK apartments, perfect for those who desire a refined lifestyle enriched with top-notch amenities and superior connectivity. Don't miss the opportunity to buy a flat in Noida Extension with modern conveniences. 
        </p>
        
        <h3 className="subheading">Prime Location</h3>
        <p className="section-text">
          Nestled in Sector 12, <strong>ACE Hanei</strong> New Launch boasts exceptional connectivity to the vibrant hubs of Delhi NCR. Residents benefit from convenient access to essential services such as reputable schools, healthcare facilities, shopping centers, and entertainment venues, all just moments away. This is the ideal place to buy flats in Noida Extension, offering an unbeatable location. 
        </p>

        <h3 className="subheading">Spacious Living</h3>
        <p className="section-text">
          Choose from a variety of spacious living options designed for your comfort. The project features elegant 3.5 BHK apartments (range 2290 sq. ft.), luxurious 4.5 BHK apartments (3200 sq. ft.), and expansive 4.5 BHK XL units (4190 sq. ft.). Each home is meticulously planned to provide ample space for family living. If you're looking to buy flats in Greater Noida West, this project is a perfect match. 
        </p>
        
        <h3 className="subheading">Modern Amenities</h3>
        <p className="section-text">
          Indulge in a lifestyle of luxury with a host of modern amenities, including a state-of-the-art clubhouse, a refreshing swimming pool, a well-equipped fitness center, and beautifully landscaped outdoor areas. Relax and unwind in a serene environment that enhances your living experience. Ideal for anyone considering properties in Noida Extension, <strong>ACE Hanei</strong> offers top-tier amenities.
        </p>
        
        <h3 className="subheading">Project Details</h3>
        <p className="section-text">
          <ul>
            <li>Total Land: 6.6 acres</li>
            <li>Total Towers: 6</li>
            <li>G+25 stories</li>
            <li>4 lifts on each floor</li>
            <li>Grand triple-height lobby</li>
            <li>Inclusions: Air conditioners, modular kitchen, wardrobes, and Italian flooring.</li>
          </ul>
        </p>

        <h3 className="subheading">Pricing and Offers</h3>
        <p className="section-text">
          <ul>
            <li>Price: ₹12,499 per sq. ft.</li>
            <li>Discount: ₹1,500 per sq. ft.</li>
            <li>Effective Price: ₹10,999 per sq. ft. (for the first 100-150 units)</li>
            <li>Payment Plan: CLP</li>
            <li>First transfer free (for the first 100 units)</li>
            <li>Clubhouse fee: Approximately ₹50,000</li>
            <li>Booking Amount: ₹10 lakh for 2290 sq. ft. units and ₹15 lakh for 3200-4190 sq. ft. units.</li>
          </ul>
        </p>

        <h3 className="subheading">State-of-the-Art Amenities</h3>
        <p className="section-text">
          <ul>
            <li>Clubhouse: A well-equipped space for social gatherings and activities.</li>
            <li>Swimming Pool: A luxurious swimming pool for relaxation and fitness.</li>
            <li>Fitness Center: A modern gym with cutting-edge equipment.</li>
            <li>Sports Facilities: Tennis courts, badminton courts, and jogging tracks.</li>
            <li>Children's Play Area: Safe and enjoyable spaces for kids to play.</li>
          </ul>
        </p>
        
        <h3 className="subheading">Eco-Friendly Living</h3>
        <p className="section-text">
          <ul>
            <li>Landscaped gardens and ample green spaces for a tranquil environment.</li>
            <li>Rainwater harvesting and eco-friendly practices to promote sustainability.</li>
          </ul>
        </p>
        
        <h3 className="subheading">Security</h3>
        <p className="section-text">
          <ul>
            <li>24/7 security with comprehensive CCTV surveillance.</li>
            <li>Gated community with trained security personnel for enhanced safety.</li>
          </ul>
        </p>
        
        <h3 className="subheading">Connectivity</h3>
        <p className="section-text">
          <ul>
            <li>Close to major roads and highways for hassle-free commuting.</li>
            <li>Proximity to schools, hospitals, shopping malls, and entertainment hubs, making life convenient.</li>
          </ul>
        </p>
        
        <p className="section-text">
          Experience an exceptional living standard at ACE New Launch in Sector 12, Noida Extension, where luxurious apartments, modern amenities, and an unparalleled location come together to offer the ultimate comfort and convenience for discerning homebuyers. Explore the wide range of flats in Noida Extension and secure your dream home today! Buy a flat in Greater Noida West and enjoy a high-quality lifestyle.
        </p>
      </div>
    </div>
  );
};

export default Text;
