import React from "react";
import "../About/About.css";
import Text from "../Text/Text";
import Plan from "../Plan/Plan";
import Details from "../Details/Details";
import { FaArrowDown } from "react-icons/fa";

const About = () => {
  const leftPoints = [
    "Unique Glass Facade Towers offering expansive 3-side open homes in Sector 152 Noida.",
    "Spacious and well-designed 3.5, 4.5 & 4.5XL BHK residences in an ultra-luxury development.",
    "Low-density project with double-height entrance lobbies, maximizing space and natural light.",
    "High-end finishes with modular kitchen, wardrobes, and Italian marble flooring in living and dining areas.",
    "Secured gated community with 24/7 security and CCTV surveillance.",
    "Located next to the proposed metro station and close to the upcoming Noida International Airport."
  ];
  
  const rightPoints = [
    "Amenities include a well-equipped clubhouse, swimming pool, and fitness center.",
    "Sports facilities: tennis courts, badminton courts, and jogging tracks.",
    "Eco-friendly living with landscaped gardens and sustainable practices like rainwater harvesting.",
    "Proximity to schools, hospitals, shopping malls, and entertainment hubs.",
    "Children's play area with safe and engaging play zones.",
    "Strategic location with excellent connectivity to Sector-52 Metro Station and key areas of Noida."
  ];
  

  return (
    <>
      <div className="poster-section2">
        <img src="a1.jpg" alt="Poster" className="poster-image2" />
      </div>
      <div className="about-section">
        <h2 className="section-heading2">ACE Hanei Sector 12 Noida Extension</h2>
        <div className="about-content">
          {leftPoints.map((point, index) => (
            <div key={index} className="carda">
              <FaArrowDown className="iconar" />
              <p className="card-content">{point}</p>
            </div>
          ))}
          {rightPoints.map((point, index) => (
            <div key={index} className="carda">
              <FaArrowDown className="iconar" />
              <p className="card-content">{point}</p>
            </div>
          ))}
        </div>
      </div>

      <Details />
    </>
  );
};

export default About;
